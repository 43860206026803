import React from 'react'
import { SupplierRow } from 'components/pages/suppliers'
import { TableContent, TableLoading } from 'components/common'
import { connectInfiniteHits, Configure } from 'react-instantsearch-dom'
import { SUPPLIER } from 'constants/resources'
import { useResource, useStoreAlgolia, useSelectResource, useDeviceColumns, useFacetFilters } from 'components/hooks'
import { suppliers } from 'store/action-creators'
import PropTypes from 'prop-types'

function SupplierContent({ hits, hasMore, refineNext, hasPrevious, refinePrevious, columns }) {
  const selected = useResource(SUPPLIER, 'current', 'id')
  const list = useStoreAlgolia(SUPPLIER, suppliers, hits)
  const loading = useResource(SUPPLIER, 'list', 'loading')
  const handleRowClick = useSelectResource(SUPPLIER)
  const templateColumns = useDeviceColumns(columns, !!selected)
  const facetFilters = useFacetFilters(SUPPLIER)
  return (
    <TableContent next={refineNext} dataLength={hits.length} hasMore={hasMore}>
      <Configure facetFilters={facetFilters} hitsPerPage={40} />
      <TableLoading loading={loading} />
      {list.map((supplier) => (
        <SupplierRow
          key={supplier.get('id')}
          id={supplier.get('id')}
          shrink={!!selected}
          templateColumns={templateColumns}
          name={supplier.get('name')}
          branch={supplier.get('branch')}
          categories={supplier.get('categories')}
          state={supplier.get('address_state')}
          city={supplier.get('address_city')}
          rating={+supplier.get('overall_rating')}
          totalOrders={supplier.get('total_order_items')}
          active={supplier.get('id') === selected}
          onClick={() => handleRowClick(supplier.get('id'))}
        />
      ))}
    </TableContent>
  )
}

SupplierContent.propTypes = {
  columns: PropTypes.object.isRequired,
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  refineNext: PropTypes.func.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refinePrevious: PropTypes.func.isRequired
}

export default connectInfiniteHits(SupplierContent)
