import api from 'config/axios'
import { buildQueryString } from 'utils'

export const list = async (filters) => api.get(`/app/suppliers/?${buildQueryString(filters)}`)

export const get = async (id) => api.get(`/app/suppliers/${id}/`)

export const create = async ({
  name,
  branch,
  contact_name,
  contact_number,
  main_contact_number,
  notes,
  categories,
  address_line1,
  address_line2,
  address_city,
  address_state,
  address_zip,
  address_country,
  metadata
}) =>
  api.post('/app/suppliers/', {
    name,
    branch,
    contact_name,
    contact_number,
    main_contact_number,
    notes,
    categories,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    metadata
  })

export const update = async (
  id,
  {
    name,
    branch,
    contact_name,
    contact_number,
    main_contact_number,
    notes,
    categories,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    metadata
  }
) =>
  api.patch(`/app/suppliers/${id}/`, {
    name,
    branch,
    contact_name,
    contact_number,
    main_contact_number,
    notes,
    categories,
    address_line1,
    address_line2,
    address_city,
    address_state,
    address_zip,
    address_country,
    metadata
  })

export const updatePriceSheet = async (id, body) => api.patch(`/app/suppliers/${id}/`, body)

export const destroy = async (id) => api.delete(`/app/suppliers/${id}/`)
