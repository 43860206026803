import React, { useEffect, useState } from 'react'
import { useCurrent, useResource, useRatings } from 'components/hooks'
import { SUPPLIER, SUPPLIER_REVIEW } from 'constants/resources'
import { useDispatch } from 'react-redux'
import { supplierReviews } from 'store/action-creators'
import { Divider } from 'components/ui'
import { AddressSection, Destroy, TableDetail } from 'components/common'
import Information from './detail/Information'
import Reviews from './detail/Reviews'
import OrderItemList from './detail/OrderItemList'
import Header from './detail/Header'
import ReviewNotes from './detail/ReviewNotes'

function SupplierDetail() {
  const [count, setCount] = useState(1)
  const selected = useCurrent(SUPPLIER)
  const dispatch = useDispatch()
  const loading = useResource(SUPPLIER, 'current', 'loading')
  const reviews = useResource(SUPPLIER_REVIEW, 'list')
  const { deliveries, serviceCalls, responseTime, pricing } = useRatings(reviews)
  const canDestroy = count

  useEffect(() => {
    if (selected.get('id')) {
      dispatch(
        supplierReviews.getList({
          supplier: selected.get('id'),
          limit: 99
        })
      )
    }
  }, [dispatch, selected])

  return (
    <TableDetail>
      <Header title={selected.get('name')} priceListUrl={selected.get('price_sheet')} loading={loading} />
      <Divider spacing={28} />
      <Information
        name={selected.get('name')}
        branch={selected.get('branch')}
        categories={selected.get('categories')}
        code={selected.get('code')}
        branchNumber={selected.get('main_contact_number')}
        contactName={selected.get('contact_name')}
        contactNumber={selected.get('contact_number')}
        hasCreditCard={selected.getIn(['metadata', 'hasCreditCard'])}
        hasAccount={selected.getIn(['metadata', 'hasAccount'])}
        notes={selected.get('notes')}
        loading={loading}
      />
      <Divider spacing={20} />
      <AddressSection
        street={selected.get('address_line1')}
        streetDetails={selected.get('address_line2')}
        city={selected.get('address_city')}
        state={selected.get('address_state')}
        zipcode={selected.get('address_zip')}
        country={selected.getIn(['address_country', 'name'])}
        loading={loading}
      />
      <Divider spacing={20} />
      <Reviews
        supplierId={selected.get('id')}
        loading={loading}
        deliveries={deliveries}
        serviceCalls={serviceCalls}
        responseTime={responseTime}
        pricing={pricing}
      />
      <Divider spacing={20} />
      <ReviewNotes supplierId={selected.get('id')} />
      <Divider spacing={20} />
      <OrderItemList onCount={setCount} supplierId={selected.get('id')} />
      <Destroy resource={SUPPLIER} id={selected.get('id')} show />
    </TableDetail>
  )
}

export default SupplierDetail
