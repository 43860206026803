import React from 'react'
import { SupplierContent, SupplierHeader, SupplierControls } from 'components/pages/suppliers'
import { withAlgolia } from 'components/hocs'
import {
  SUPPLIER_INDEX,
  SUPPLIER_STATE_ASC_INDEX,
  SUPPLIER_STATE_DESC_INDEX,
  SUPPLIER_CITY_ASC_INDEX,
  SUPPLIER_CITY_DESC_INDEX,
  SUPPLIER_TOTALORDERS_ASC_INDEX,
  SUPPLIER_TOTALORDERS_DESC_INDEX,
  SUPPLIER_RATING_ASC_INDEX,
  SUPPLIER_RATING_DESC_INDEX
} from 'constants/algolia'
import { SUPPLIER } from 'constants/resources'
import { useTranslation } from 'react-i18next'
import { Table } from 'components/common'

function SupplierTable() {
  const { t } = useTranslation('common')
  const columns = {
    sm: '2fr 1fr 1fr',
    md: '1fr 1fr 1fr 1fr 1fr',
    lg: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    xl: '1fr 1fr 1fr 1fr 1fr 1fr 1fr'
  }
  return (
    <Table
      title={t('resources.suppliers.plural')}
      controls={<SupplierControls />}
      resource={SUPPLIER}
      header={
        <SupplierHeader
          columns={columns}
          defaultRefinement={SUPPLIER_INDEX}
          items={[
            SUPPLIER_INDEX,
            SUPPLIER_STATE_ASC_INDEX,
            SUPPLIER_STATE_DESC_INDEX,
            SUPPLIER_CITY_ASC_INDEX,
            SUPPLIER_CITY_DESC_INDEX,
            SUPPLIER_TOTALORDERS_ASC_INDEX,
            SUPPLIER_TOTALORDERS_DESC_INDEX,
            SUPPLIER_RATING_ASC_INDEX,
            SUPPLIER_RATING_DESC_INDEX
          ]}
        />
      }
      content={<SupplierContent columns={columns} />}
    />
  )
}

export default withAlgolia(SUPPLIER_INDEX)(SupplierTable)
